// src/appkitConfig.js
import { defineChain } from '@reown/appkit/networks';
// import { createAppKit } from '@reown/appkit/react';


import { createAppKit } from '@reown/appkit/react';
import { EthersAdapter } from '@reown/appkit-adapter-ethers';
import { bsc } from '@reown/appkit/networks';

const bnbTestnet = defineChain({
    id: 97, // Chain ID for BNB Testnet
    caipNetworkId: 'eip155:97', // CAIP-2 compliant network ID
    chainNamespace: 'eip155', // Chain namespace for EVM
    name: 'BNB Testnet',
    nativeCurrency: {
      decimals: 18,
      name: 'Binance Coin',
      symbol: 'BNB',
    },
    rpcUrls: {
      default: {
        http: ['https://data-seed-prebsc-1-s1.binance.org:8545/'], // RPC HTTP URL
      },
    },
    blockExplorers: {
      default: { name: 'BscScan', url: 'https://testnet.bscscan.com' }, // Block Explorer
    },
  });

const projectId = 'ca2907848958b14d9b0d289b430f3c15'; // Your Project ID
const networks = [bsc, bnbTestnet];
const metadata = {
  name: 'ChurroSwap',
  description: 'Decentralized and Community Driven',
  url: 'https://churroswap.io/',
  icons: [''],
};


createAppKit({
  themeMode: 'light',

   defaultNetwork:bsc,
  adapters: [new EthersAdapter()],
  networks,
  metadata,
  projectId,
  features: {
    analytics: true,email: false, 
    socials: [],
    emailShowWallets: true,
    swaps: false,
    onramp: true
  },
  themeVariables:{
    '--w3m-accent':'#2e130e',
"--w3m-color-mix":'#2e130e',
'--w3m-network-button-accent':'black' 
 }
});
