import ApproveA from './approve'
import ApproveB from './approveB';
import AddLiquidity from './addliquidity';
import Pair from './createPair'
import MintToken from './mintTokens';


import ApproveHardhat from './hardhatTesting/approvecopy'
import AddLiquidityhardhat from './hardhatTesting/addliquiditycopy'
import Pairhardhat from './hardhatTesting/createPaircopy'
import Minthardhat from './hardhatTesting/mintTokenscopy'



const SwapPage = () => {

  return (
    
    <div className='liquidity-background'>   
       <h3>Add your own liquidity coming soon</h3> 
        
        <Pair/>
        <ApproveA/>
        <ApproveB/>
        <AddLiquidity/>
       <MintToken/>
      <br></br>
       <h3>HARDHAT TESTING</h3>
       <br></br>
       <Pairhardhat/>
       <ApproveHardhat/>
       <AddLiquidityhardhat/>
       <Minthardhat/> 


    </div>
  );
};

export default SwapPage;
