import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useAppKitAccount } from '@reown/appkit/react'; // Import the correct hook for account info
import { setConnectedWallet, setAddress } from '../../app/connectSlice';

export const ConnectButton = () => {
  const dispatch = useDispatch();

  // Reown's hook to access account details
  const { address, isConnected } = useAppKitAccount();

  // Redux state for wallet address
  const accountAddress = useSelector((state) => state.connect.account);

  // Synchronize the Reown wallet address with Redux
  useEffect(() => {
    if (isConnected && address) {
      dispatch(setConnectedWallet(true));
      dispatch(setAddress(address));
      console.log(`Address synced with Redux: ${address}`);
    } else {
      dispatch(setConnectedWallet(false));
      dispatch(setAddress('Account Null'));
      console.log('No wallet connected. Connect Button');
    }
  }, [address, isConnected, dispatch]);

  return (
    <div style={{ display: 'flex', gap: '10px', alignItems: 'center' }}>
      
      <appkit-network-button/>
      <appkit-button />

    </div>
  );
};
