import * as React from 'react';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import Slide from '@mui/material/Slide';
import TextField from '@mui/material/TextField';
import { init, send } from '@emailjs/browser'; // Import the init and send functions
import subscribe from '../../logos/subscribe.png'
import { useNavigate } from 'react-router-dom';
import {Link} from 'react-router-dom'
import { Carousel } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { keyframes } from '@mui/system';
import image1 from '../../logos/churro1.jpg'
import image2 from '../../logos/churro2.jpg'
import image3 from '../../logos/churro3.jpg'
import image4 from '../../logos/churro4.jpg'
import image5 from '../../logos/churro5.jpg'
import image7 from '../../logos/churro6.jpg'
import image8 from '../../logos/churro7.jpg'
import image9 from '../../logos/churro8.jpg'
import image10 from '../../logos/churro10.jpg'
import image11 from '../../logos/churro11.jpg'
import image12 from '../../logos/churro12.jpg'
import image13 from '../../logos/churro13.jpg'
import image14 from '../../logos/churro14.jpg'
// Initialize EmailJS with your public key
// It's better to do this once, possibly in a higher-level component or your entry file
init("Z4tV3jN-HOrbJEHlY");

const StyledDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
  borderRadius: theme.spacing(2),
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

const CloseButton = styled(Button)(({ theme }) => ({
  '&:hover': {
    backgroundColor: 'rgb(51, 20, 15,0.7)',
  },
}));

export default function EmailCollector() {
  const [open, setOpen] = React.useState(false);
  const [name, setName] = React.useState('');
  const [email, setEmail] = React.useState('');

  React.useEffect(() => {
    setOpen(true);
  }, []);

  const handleClose = () => {
    setOpen(false);
  };
  
  const navigate = useNavigate();

  const handleSubmit = (e) => {
    e.preventDefault();
    send('service_ogjgygh', 'template_xl6claa', {
      to_name: 'Teddy',
      from_name: name,
      message:email,


    })
      .then((result) => {
        console.log('SUCCESS!', result.status, result.text);
        handleClose(); // Close the dialog on success
        navigate('/sometercorreo');
        
      }, (error) => {
        console.log('FAILED...', error);
      });
  };

  const isValidEmail = email.includes('@');
  const isButtonDisabled = !email || !isValidEmail;



  const scroll = keyframes`
  0% {
    transform: translateX(0%);
  }
  100% {
    transform: translateX(-50%);
  }
`;

const images = [
  image1, 
  image2, 
  image3, 
  image4, 
  image5, 
  image7, 
  image8, 
  image9,
  image10,
  image11,
  image12,
  image13,
  image14,
  // add more images as needed
];
const ScrollingBar = styled('div')({
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  background: '#white',
  '& div': {
    display: 'inline-block',
    animation: `${scroll} 20s linear infinite`,
  },
  '& img': {
    height: '100px',
    borderRadius:'10px',
    marginRight: '10px',
    
  },
});

const duplicatedImages = [...images, ...images];



  return (
    <StyledDialog
      open={open}
      TransitionComponent={Transition}
      keepMounted
      onClose={handleClose}
      aria-labelledby="alert-dialog-slide-title"
      aria-describedby="alert-dialog-slide-description"
      PaperProps={{ style: { borderRadius: '20px' } }}
    >

      <h3 style={{background:'#f9b435',color:'white',paddingLeft:'5em',paddingBottom:'.2em'}}>¡Ya Vienen Los Churritos!</h3>
      <div>
     <ScrollingBar>
     <div>
          {duplicatedImages.map((image, index) => (
            <img key={index} src={image} alt={`img-${index}`} />
          ))}
        </div>
   
  </ScrollingBar>
  </div>
      <form onSubmit={handleSubmit} style={{ textAlign: 'center' }}>
      <DialogTitle style={{ textAlign: 'center',color: 'rgb(51, 20, 15)',fontWeight:'bold',borderStyle:'solid',borderRadius:'10px' }} id="alert-dialog-slide-title">{"Subscribe tu email y ser una de las primeras personas en obtener NFTs exclusivos, juegos y mas!"}</DialogTitle>
      <br></br>
      <DialogTitle style={{ textAlign: 'center',color: 'rgb(51, 20, 15)',fontWeight:'bold',borderStyle:'solid',borderRadius:'10px'  }} id="alert-dialog-slide-title">{"Enter your email and be one of the first to obtain exclusive NFTs, access to games and more!"}</DialogTitle>
{/* <p>ChurroSwap se lanzara pronto!</p> */}
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            id="name"
            label="Nombre/Name"
            type="text"
            fullWidth
            variant="outlined"
            value={name}
            onChange={(e) => setName(e.target.value)}
            sx={{
              '& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
                borderWidth: '2px', // Adjust the thickness as needed
              },
              '&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
                borderWidth: '2px', // Ensure the border width stays the same on hover
              },
              '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
                borderWidth: '2px', // Ensure the border width stays the same when focused
                borderColor: 'primary.main', // Optional: change the border color when focused
              },
             
                color: 'rgb(51, 20, 15)', 
                fontWeight: 'bold',
        //         '&:hover': {
        //           backgroundColor: 'rgb(51, 20, 15,.25)',
        //   color: 'hoverTextColor', 
        // }
    
            }}
          />
          <TextField
            margin="dense"
            id="email"
            label="Correo Electronico/Email"
            type="email"
            fullWidth
            variant="outlined"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            sx={{
              '& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
                borderWidth: '2px', // Adjust the thickness as needed
              },
              '&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
                borderWidth: '2px', // Ensure the border width stays the same on hover
              },
              '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
                borderWidth: '2px', // Ensure the border width stays the same when focused
                borderColor: 'primary.main', // Optional: change the border color when focused
              },
             
                color: 'rgb(51, 20, 15)', 
                fontWeight: 'bold',
        //         '&:hover': {
        //           backgroundColor: 'rgb(51, 20, 15,.25)',
        //   color: 'hoverTextColor', 
        // }
    
            }}
            
          />
        </DialogContent>
        <DialogActions >


        <Button color="primary"sx={{
            color: 'rgb(51, 20, 15)', 
            fontWeight: 'bold',
            '&:hover': {
              backgroundColor: 'rgb(51, 20, 15,.25)',
      color:'hoverTextColor'
      
    }
  }}>
        <a href="https://discord.com/channels/1247310889200390264/1250730894009045056" style={{textDecoration:'none',color:'black'}}>Join the Discord</a>
        </Button>





          <CloseButton onClick={handleClose} color="primary"sx={{
            color: 'rgb(51, 20, 15)', 
            fontWeight: 'bold',
            '&:hover': {
              backgroundColor: 'rgb(51, 20, 15,.25)',
      color:'hoverTextColor'
      
    }
  }}>
            Cancelar
          </CloseButton>
          <Button
           disabled={isButtonDisabled}
          type="submit" sx={{
            color: 'rgb(51, 20, 15)', 
            fontWeight: 'bold',
            '&:hover': {
              backgroundColor: 'rgb(51, 20, 15,.25)',
      color: 'hoverTextColor', 
      
    }
  }}>
            Subscribir
          </Button>

          
        </DialogActions>
      </form>
      {/* <img src={subscribe} style={{alignContent:'center',height:'5em',width:'5em'} }alt='churro'></img> */}
    </StyledDialog>
  );
}